import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux'
import { Provider as PaperProvider } from 'react-native-paper';
import { combineReducers, createStore, applyMiddleware } from 'redux'
import ReduxThunk from 'redux-thunk'
import translateRedux from './store/reducers/translate';
import cartReducer from './store/reducers/cart';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as Font from 'expo-font';

import Router from './router/index'

const reducers = combineReducers({
  cart: cartReducer,
  translate: translateRedux
})

const store = createStore(reducers, applyMiddleware(ReduxThunk))


export default function App() {
  const [fontLoaded, setFontLoaded] = useState(false)

  useEffect(() => {
    async function load() {
      await Font.loadAsync({ 'Regular': require('./fonts/TT-Font-Regular.ttf') })
      await Font.loadAsync({ 'ExtraBold': require('./fonts/TT-Font-ExtraBold.ttf') })
      await Font.loadAsync({ 'TTFMedium': require('./fonts/TT-Font-Medium.ttf') })
      setFontLoaded(true)
    }
    load()
  }, [])
  return (
    // <View style={styles.container}>
    //   <Text>Open up App.js to start working on your app!</Text>
    //   <StatusBar style="auto" />
    // </View>

    <Provider store={store} >
      <PaperProvider>
        {
          fontLoaded ?
            <Router />
            :
            <></>
        }

      </PaperProvider>

    </Provider>
  );
}

