import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Dimensions } from 'react-native'
import { Feather } from '@expo/vector-icons'

export default function Board(props) {

    const letter = [
        'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P',
        'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', '-',
        'Z', 'X', 'C', 'V', 'B', 'N', 'M', '\'', '#', '.'
    ]

    const number = [
        '1', '2', '3', '4', '5', '6', '7', '8', '9'
    ]

    return (
        <View style={styles.container} >
            <View style={{ flexDirection: 'row' }} >
                <View style={{ width: width < 1024 ? 460 : 510, flexDirection: 'row', flexWrap: 'wrap' }} >
                    {
                        letter.map((item, index) => {
                            return (
                                <TouchableOpacity style={styles.keyStyles} key={index} onPress={() => props.changeVaule(item, props.select)} >
                                    <Text style={{ fontFamily: 'Regular', fontSize: 13 }}>{item}</Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                    <TouchableOpacity style={[styles.keyStyles, { width: width < 1024 ? 275 : 305 }]} onPress={() => props.changeVaule('\xa0', props.select)}>
                        <Text style={{ fontFamily: 'Regular', fontSize: 13 }}>spance</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.keyStyles]} onPress={() => props.changeVaule('delete', props.select)}>
                        <Feather name="delete" size={20} color="black" />
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.keyStyles]} onPress={() => props.changeVaule(':', props.select)} >
                        <Text style={{ fontFamily: 'Regular', fontSize: 13 }}>:</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.keyStyles]} onPress={() => props.changeVaule('/', props.select)} >
                        <Text style={{ fontFamily: 'Regular', fontSize: 13 }}>/</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.keyStyles]} onPress={() => props.changeVaule('*', props.select)}>
                        <Text style={{ fontFamily: 'Regular', fontSize: 13 }}>*</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', width: width < 1024 ? 138 : 153, marginLeft: 10 }} >
                    {
                        number.map((item, index) => {
                            return (
                                <TouchableOpacity style={styles.keyStyles} key={index} onPress={() => props.changeVaule(item, props.select)} >
                                    <Text style={{ fontFamily: 'Regular', fontSize: 13 }}>{item}</Text>
                                </TouchableOpacity>
                            )
                        })
                    }
                    <TouchableOpacity style={[styles.keyStyles, { width: width < 1024 ? 91 : 101 }]} onPress={() => props.changeVaule('0', props.select)}>
                        <Text style={{ fontFamily: 'Regular', fontSize: 13 }}>0</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.keyStyles]} onPress={() => props.changeVaule('enter', props.select)}>
                        <Text style={{ fontFamily: 'Regular', fontSize: 13 }}>Enter</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}
const { width, height } = Dimensions.get('window')
const styles = StyleSheet.create({
    container: {
        marginTop: 30,
        justifyContent: 'center',
        alignItems: 'center'
    },
    keyStyles: {
        width: width < 1024 ? 45 : 50,
        height: width < 1024 ? 45 : 50,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#99CCFF',
        borderRadius: 5,
        margin: 0.5,
        borderWidth: 0.5,
        borderColor: 'rgb(153,153,153)',
    }
})